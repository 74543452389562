import { Component, ContentChild, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'ta-menu-list-item, div[ta-menu-list-item]',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuListItemComponent {
  @HostBinding('class')
  readonly class = 'menu-list-item mat-list-item';

  @ContentChild(MatMenu, { static: false })
  menu: MatMenu;

  @Input()
  icon;

  @Input()
  menuIcon = 'more_vert';

  @Output()
  itemClick: EventEmitter<void> = new EventEmitter();

  onItemClick(): void {
    this.itemClick.emit();
  }
}
