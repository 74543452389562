<div fxLayout="row" class="row" fxLayoutAlign="space-between stretch">
  <div class="item-content" fxFlex="99" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" (click)="onItemClick()">
    <div class="item-icon" *ngIf="!!icon">
      <mat-icon>{{ icon }}</mat-icon>
    </div>
    <ng-content select="[content]"></ng-content>
  </div>

  <div fxFlex fxLayoutAlign="end center" class="item-menu-trigger nav-icon-wrapper" (click)="trigger.openMenu()" *ngIf="menu; else noMenu">
    <mat-icon aria-hidden="true" role="presentation" class="nav-icon" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">{{ menuIcon }}</mat-icon>
  </div>
</div>

<ng-template #noMenu>
  <div fxFlex fxLayoutAlign="end center" class="item-menu-trigger nav-icon-wrapper">
    <mat-icon aria-hidden="true" role="presentation" class="nav-icon">{{ menuIcon }}</mat-icon>
  </div>
</ng-template>
