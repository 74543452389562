/**
 * Hypertext Transfer Protocol (HTTP) response status codes.
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes}
 */
export enum HttpStatusCode {
  /**
   * The request has succeeded.
   */
  Success = 200,
  /**
   * The request has not been applied because it lacks valid authentication credentials for the target resource.
   */
  Unauthorized = 401,
  /**
   * The requested resource could not be found but may be available in the future.
   * Subsequent requests by the client are permissible.
   */
  NotFound = 404,
  /**
   * The server encountered an unexpected condition that prevented it from fulfilling the request.
   */
  InterlServerError = 500
}
