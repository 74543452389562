// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://management.translation.dev.clario.com',
  auth: {
    redirectUri: window.location.origin,
    loginUrl: 'https://gsso-03.dev.ert.com/service/api/v1/oauth2/authorize',
    clientId: 'translationservice',
    logoutUrl: 'https://gsso-03.dev.ert.com/gsso/services/api/v1/sessions/logout',
    checkSessionUrl: 'https://gsso-03.dev.ert.com/gsso/services/api/v1/sessions/check',
    rolesUrl: 'https://gsso-03.dev.ert.com/gsso/services/api/v2/users/roles'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
